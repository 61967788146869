<script setup lang="ts">
const props = defineProps<{
	label?: string
	severity?: 'error'
	info?: string
	isWide?: boolean
	noMargin?: boolean
}>();

// Info Tooltip
function getTooltipContent() {
	return `
		<span class="tooltip">
			${props.info ? `<span class="tooltip__description">${props.info}</span>` : ''}
		</span>
	`;
}
</script>

<template>
	<div
		class="data-container"
		:class="[{
			[`data-container--${props.severity}`]: props.severity,
			'data-container--wide': props.isWide,
			'data-container--no-margin': props.noMargin,
		}]"
	>
		<div class="data-container__header" v-if="props.label">
			<span class="data-container__title">{{ props.label }}</span>
			<button
				type="button"
				class="data-container__info"
				v-if="props.info"
				v-pv-tooltip.top="{
					value: getTooltipContent(),
					escape: false,
				}"
			>
				<Icon name="info-empty" size="18" />
			</button>
		</div>
		<div class="data-container__body">
			<slot />
		</div>
	</div>
</template>
